import { WithStoryProps } from '@storyofams/storyblok-toolkit';

import { Seo } from '~/components';
import { BannerDynamic } from './Banners/BannerDynamic';
import { Block } from './Blocks';

export interface DynamicPageProps extends WithStoryProps {
  story: any;
  className?: string;
}

export const DynamicPage = ({ story, className }: DynamicPageProps) => {
  const tableOfContents = story?.content?.get_content
    ? {
        getContent: story?.content?.get_content,
        manualContent: story?.content?.manual_content,
      }
    : undefined;

  return (
    <div>
      <Seo story={story} />

      {!!story?.content?.body?.[0]?.component &&
        story?.content?.body?.map((section, i) => {
          return (
            <Block
              component={story?.content?.component}
              sectionType={section?.component}
              content={section}
              first={i === 0}
              key={section?._uid}
              title={story?.content?.seo?.title || story?.content?.title}
              tableOfContents={tableOfContents}
              className={className}
            />
          );
        })}

      {!!story?.content?.banner?.content &&
        story?.content?.component === 'page' && (
          <div className="w-full bg-gradient-to-b from-[#FDF7DF] to-white mt-52">
            <div className="w-full max-w-6xl mx-auto px-4 md:px-5 pt-0 relative -top-32">
              <BannerDynamic content={story?.content?.banner?.content} />
            </div>
          </div>
        )}
    </div>
  );
};
